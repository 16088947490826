export default {
  install: function (Vue) {
    // 加载更多
    Vue.directive('loadMore', {
      inserted: function (el, binding) {
        // const selectWrap = el.querySelector(".scrollbar-wrap")
        const selectWrap = el
        selectWrap.addEventListener("scroll", () => {
          const loadNow = selectWrap.scrollHeight - selectWrap.scrollTop <= 280
          if (loadNow) {
            binding.value()
          }
        })
      }
    })
  }
}

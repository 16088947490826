import request from '@/utils/request'
import store from '@/store'

// 添加设备时获取账号列表
export function accountListApi (data) {
  return request({
    url: '/User/List',
    method: 'post',
    data
  })
}
// 创建设备
export function addDeviceApi (data) {
  return request({
    url: '/Admin/Device/Add',
    method: 'post',
    data
  })
}
// 编辑设备(admin)
export function updateDeviceApi (data) {
  return request({
    url: '/Admin/Device/Edit',
    method: 'post',
    data
  })
}
// 编辑设备(通航公司)
export function updateDeviceApi2 (data) {
  return request({
    url: '/Device/Edit',
    method: 'post',
    data
  })
}
// 设备数量
export function getDeviceCountApi (data) {
  return request({
    url: '/Device/Count',
    method: 'post',
    data
  })
}
// 设备列表
export function getDeviceListApi (data) {
  const url = store.getters.isAdmin ? '/Admin/Device/List' : '/Device/List'
  return request({
    url: url,
    method: 'post',
    data
  })
}
// 设备位置-飞手
export function getDeviceCoord (data) {
  return request({
    url: '/Device/Coord',
    method: 'post',
    data
  })
}
// 设备详情-飞手
export function getDeviceDetail (data) {
  return request({
    url: '/Device/Detail',
    method: 'post',
    data
  })
}
// 客户端控制飞机
export function deviceControl (data) {
  return request({
    url: '/Device/Control',
    method: 'post',
    data
  })
}
// 获取地址
export function getDeviceLive (data) {
  return request({
    url: '/Device/Live',
    method: 'post',
    data
  })
}
// 声网
export function getAgora (data) {
  return request({
    url: '/Device/Live/Agora',
    method: 'post',
    data
  })
}

// 设备信息导出
export function exportDeviceInfo (query) {
  return request({
    url: '/Device/Export',
    method: 'post',
    params: query,
    responseType: 'blob'
  })
}

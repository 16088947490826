import request from '@/utils/request'

// 飞手列表（飞手管理）
export function driverList (data) {
  return request({
    url: '/Driver/List',
    method: 'post',
    data
  })
}
// 飞手数量（入职申请）
export function driverCount (data) {
  return request({
    url: '/Driver/Count',
    method: 'post',
    data
  })
}

// 入职申请列表
export function driverInductionList (data) {
  return request({
    url: '/Driver/Induction/List',
    method: 'post',
    data
  })
}

// 入职申请数量
export function driverInductionCount (data) {
  return request({
    url: '/Driver/Induction/Count',
    method: 'post',
    data
  })
}

// 审批入职申请
export function driverInductionApprove (data) {
  return request({
    url: '/Driver/Induction/Approve',
    method: 'post',
    data
  })
}

// 飞手申请入职
export function driverInduction (data) {
  return request({
    url: '/Driver/Induction',
    method: 'post',
    data
  })
}

// 飞手离职
export function driverQuite (data) {
  return request({
    url: '/Driver/Quite',
    method: 'post',
    data
  })
}
// 飞手登录后，若没有加入通航公司或设备服务商，则显示此界面


var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"x-button",class:[
  _vm.size ? 'x-button--' + _vm.size : '',
  _vm.type ? 'x-button--' + _vm.type : '',
  {
    'is-disabled' : _vm.disabled,
    'is-loading' : _vm.loading
  }],attrs:{"disabled":_vm.disabled || _vm.loading}},[(_vm.loading)?_c('i',{staticClass:"x-icon-loading"}):_vm._e(),(_vm.icon)?_c('svg-icon',{attrs:{"icon-class":"icon"}}):_vm._e(),(_vm.$slots.default)?_c('span',[_vm._t("default")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <el-dialog title="修改密码" :visible.sync="showDialog" width="450px" :close-on-click-modal="false" :before-close="handleDialogClose">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm" class="form formbb" label-width="80px">
        <el-form-item label="当前密码" prop="currentPassword">
          <el-input v-model.trim="ruleForm.currentPassword" type="password" placeholder="请输入当前密码" width="280px"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input v-model.trim="ruleForm.password" type="password" placeholder="请输入新密码" width="280px"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input v-model.trim="ruleForm.confirmPassword" type="password" placeholder="请确认新密码" width="280px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancelDialog">取消</el-button>
        <el-button type="primary" size="small" @click="confirmSave">确定</el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>
  import { modifyPassword } from '@/api/user.js'
  
  export default {
    props: {
      show: Boolean
    },
    data() {
      return {
        ruleForm: {
          currentPassword:'',
          password: '',
          confirmPassword: ''
        },
        rules: {
          currentPassword:[{ required:true,message: '请输入当前密码', trigger:'blur'}],
          password: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
          confirmPassword: [{ required: true, message: '请确认新密码', trigger: 'blur' }]
        }
      }
    },
    computed: {
      showDialog: {
        get() {
          return this.show
        },
        set(val) {
          this.$emit('update:show', val)
        }
      }
    },
    methods: {
      // 取消弹窗
      cancelDialog() {
        this.showDialog = false;
        this.$nextTick(() => {
          this.$refs.ruleForm.clearValidate();
        });
        // 清空输入框内容
        this.ruleForm.currentPassword = '';
        this.ruleForm.password = ''; 
        this.ruleForm.confirmPassword = ''; 
      },
      handleDialogClose(done) {
        // 重置表单内容
        this.ruleForm.currentPassword = '';
        this.ruleForm.password = ''; 
        this.ruleForm.confirmPassword = ''; 
        this.$nextTick(() => {
          this.$refs.ruleForm.clearValidate();
        });
        // 关闭对话框
        done();
      },
      // 确认修改密码
      confirmSave() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            if (this.ruleForm.password !== this.ruleForm.confirmPassword) {
              this.$message.error('两次输入的密码不一致');
              return;
            }
            modifyPassword({ 
                currentPassword:this.ruleForm.currentPassword,
                password: this.ruleForm.password }).then(res => {
              this.$message.success('密码修改成功');
              this.showDialog = false;
              this.ruleForm.currentPassword = '';
              this.ruleForm.password = ''; 
              this.ruleForm.confirmPassword = ''; 
            }).catch(err => {
              console.error(err);
              this.$message.error('密码修改失败，请重试');
            });
          }
        });
      }
    }
  };
  </script>
  
  <style lang="scss">

  </style>
  
<template>
    <el-dialog title="入职申请" :visible.sync="showDialog" width="450px" :close-on-click-modal="false">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm" class="form formbb" label-width="0">
        <el-form-item prop="id">
          <!-- <el-input v-model.trim="ruleForm.username" placeholder="请输入您要加入的设备服务商或通航公司的用户名" style="width:400px"/> -->
          <XSelect
              :value.sync="ruleForm.id"
              :options="staticData.companyOption"
              :props="{ label: 'name', value: 'id' }"
              width="180px"
            />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button type="primary" size="small" @click="confirmSave">提交申请</el-button>
      </span>
    </el-dialog>
</template>
<script>
import { driverInduction } from '@/api/driver.js'
import XSelect from '@/components/XForm/XSelect'

export default {
  components: { XSelect },
  props: {
    show: Boolean
  },
  data () {
    return {
      ruleForm: {
        id: "",
        username: ""
      },
      rules: {
        username: [{ require: true, message: '请选择您要加入的设备服务商或通航公司的用户名', trigger: 'blur' }]
      },
      staticData: {
        companyOption: [], // 通航公司
        servicerOption: [] // 设备服务商
      }
    }
  },
  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (val) {
        this.$emit("update:show", val)
      }
    }
  },
  mounted () {
    // this.ruleForm = this.$options.data().ruleForm
    this.getStaticData()
  },
  methods: {
    confirmSave () {
      if (this.ruleForm.id === "") {
        this.$message.error("请选择您要加入的设备服务商或通航公司的用户名")
        return;
      }
      for (let i = 0; i < this.staticData.companyOption.length; i++) {
        const company = this.staticData.companyOption[i];
        if (company.id === this.ruleForm.id) {
          this.ruleForm.username = company.username
          console.log(company)
        }
      }
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          driverInduction({ username: this.ruleForm.username }).then(res => {
            this.$msg.success("已提交申请")
            this.ruleForm.username = ""
          })
        }
      })
    },
    async getStaticData () {
      this.$store.dispatch('equipment/getStaticData').then((data) => {
        // this.staticData.companyOption = data.companyOption
        this.staticData.companyOption = data.companyOption.concat(data.servicerOption)
        this.staticData.servicerOption = data.servicerOption
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .form {
    .el-input {
      width: 280px;
    }
  }
</style>


function beforeunload (e) {
  e.preventDefault()
  e.returnValue = ''
}

export const bindBeforeUnload = () => {
  window.addEventListener('beforeunload', beforeunload)
}

export const unbindBeforeUnload = () => {
  window.removeEventListener('beforeunload', beforeunload)
}

export const downloadData = (data, fileName) => {
  const blobUrl = window.URL.createObjectURL(data)
  // if ('download' in document.createElement('a')) // // 非IE下载
  // navigator.msSaveOrOpenBlob(blob, fileName); // IE10+下载，有保存和打开按钮
  const a = document.createElement('a') // 注意：需要区分是否为IE浏览器
  a.style.display = 'none'
  a.download = fileName
  a.href = blobUrl
  a.click()
  a.remove()
}

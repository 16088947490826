import XButton from "./Button"

const components = [XButton]

const install = function (Vue) {
  components.forEach(component => {
    Vue.component(component.name, component)
  })
}

export default {
  install,
  XButton
}

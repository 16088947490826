import Vue from "vue"
import { Message } from 'element-ui'
// 这一段代码不能少
// 这里注意一下，我用的$msg，所以我在使用的时候也是用的this.$msg
Vue.prototype.$msg = function (msg) {
  return Message({
    message: msg,
    duration: 1500
  })
}
// 分别对success、warning和error等样式进行设置
Vue.prototype.$msg.success = function (msg) {
  return Message.success({
    message: msg,
    duration: 1500
  })
}
Vue.prototype.$msg.warning = function (msg) {
  return Message.warning({
    message: msg,
    duration: 1500
  })
}
Vue.prototype.$msg.error = function (msg) {
  return Message.error({
    message: msg,
    duration: 1500
  })
}

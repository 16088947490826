<template>
  <div class="menu-top-wrap" v-if="!([100, 99, 98,97].includes($store.getters.userType))" >
      <transition name="fade">
    <div v-if="!hideNav" class="menu-top" :class="{hideNav}">
      <li v-for="(item, index) in mouduleData" :key="index" :class="{active:$store.getters.activeModuleName === item.meta.moduleName}" @click="handleSelect(item.meta.moduleName)">
        {{item.meta.moduleName}}
      </li>
      <!-- <div class="rightscissor" ref="rightscissor" @click="startScissors">剪刀</div> -->
    </div>
     </transition>
    <!-- <div class="toggle-btn" @mouseenter="toggleNav(false)">
      <span v-if="hideNav">
        {{$store.getters.activeModuleName}}
      </span>
    </div> -->
  </div>

</template>
<script>
import { mapActions } from "vuex"
import { gsap, Sine } from 'gsap'
import {cloneDeep} from "lodash"

export default {
  data () {
    return {
      mouduleData: [],
      hideNav: false
    }
  },
  created () {
    let _data = cloneDeep(this.$store.getters.havePermissionRouter) || []
    this.mouduleData = _data.filter(item => !item?.meta.hideNav)
  },
  methods: {
    ...mapActions('permission', ["setMduleName"]),
    async handleSelect (key) {
      if (this.$store.getters.activeModuleName === key) return;
      this.mouduleData.forEach(item => {
        if (item.meta.moduleName === key) {
          this.setMduleName(key)
          this.$router.replace({
            path: item.path
          })
        }
      })
    },
    changeRouter () {
      console.log(this.$store.getters.havePermissionRouter);
    },
    startScissors () {
      this.scissorAnim(this.$refs.rightscissor, 30)
    },
    scissorAnim (el, rot) {
      gsap.to(el, {
        duration: 0.25,
        rotation: rot,
        repeat: 3,
        yoyo: true,
        svgOrigin: '50 45',
        ease: Sine.easeInOut
      });
    },
    toggleNav (status) {
      setTimeout(() => {
        this.hideNav = status
      }, 300)
    }
  }
}
</script>
<style lang="scss" scoped>
.menu-top-wrap {
  width: 560px;
  position: absolute;
  z-index: 9;
  left: 50%;
  transform: translate(-50%, 0);
  top:40px;
}
.menu-top {
  width: 500px;
  height: 38px;
  margin: 0 auto;
  background: url("~@/assets/img/nav-bg.png") top center no-repeat;
  background-size: contain;
  display: flex;
  justify-content:space-around;
  overflow:hidden;
  // &.hideNav {
  //   // height: 0;
  //   overflow: hidden;
  //   margin-top: -38px;
  // }
  li {
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    height: 38px;
    line-height: 38px;
    padding: 0 2px;
    &:last-child {
      padding-right: 0;
    }
    &.active {
      color: #ffa91f;
    }
    &:hover {
      background: rgba($color: #fff, $alpha: 0.2);
    }
  }
}
.toggle-btn {
  width: 131px;
  height: 24px;
  margin: 0 auto;
  cursor: pointer;
  margin-top: -2px;
  background: url("~@/assets/img/nav-bg-btn.png") top center no-repeat;
  background-size: contain;
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
}
.fade-enter-active, .fade-leave-active {
  transition: height .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  height: 0;
}
</style>

<template>
  <!-- <transition-group> -->
  <transition class="slide-fade">
    <div class="my-notify" v-if="notifyFlag">
      <div class="notify task" v-if="taskNum > 0">
        <div class="tip">
          <span>区域任务数：</span>
          <a onclick="goTaskMapPage()" style="color: red; text-decoration: underline;">{{ taskNum }}</a>
          <!-- <span class="content">{{ taskNum }}</span> -->
        </div>
      </div>
      <div class="notify task" v-if="lineNum > 0">
        <div class="tip">
          <span>航线任务数：</span>
          <a onclick="goLineMapPage()" style="color: red; text-decoration: underline;">{{ lineNum }}</a>
          <!-- <span class="content">{{ lineNum }}</span> -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import router from '@/router'

export default {
  data () {
  },
  mounted () {
    window.goTaskMapPage = this.goTaskMapPage
    window.goLineMapPage = this.goLineMapPage
  },
  methods: {
    // 跳转区域任务
    goTaskMapPage () {
      router.push({ path: '/task', query: { curNav: '区域任务管理' }})
    },
    // 跳转航线任务
    goLineMapPage () {
      router.push({ path: '/task', query: { curNav: '航线任务管理' }})
    }
  }
}
</script>

<style scoped>
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to{
  transform: translateX(10px);
  opacity: 0;
}

.notify-wrap{
  background-color: #1AFA29;
}

.my-notify{
  margin: -40px;
  width: 160px;
}

.notify{
  position: relative;
  right: 10px;
  padding-left: 10px;
  width: 160px;
  height: 30px;
  border-radius: 4px;
  background-color: rgb(255, 244, 224);
  box-shadow: -5px 5px 12px 0 rgba(204, 204, 204, .8);
  animation: show cubic-bezier(.18, .89, .32, 1.28) .4s;
}

.task{
  border-left: 5px solid #1AFA29;
}

.notify .tip{
  height: 30px;
  margin-bottom: 5px;
  line-height: 30px;
}

.notify .tip span{
  line-height: 30px;
  font-size: 14px;
  font-weight: 600;
}

.notify .content{
  width: 160px;
  height: 30px;
  font-size: 15px;
}

@keyframes show{
  0%{
    right: -160px;
  }
  100%{
    right: 10px;
  }
}
</style>

<template>
  <div class="xy-select">
    <el-select @change="change" ref="selectObj" :style="{width}" v-if="!disabled || readonly" :disabled="readonly" v-model="val" :multiple="multiple" :clearable="clearable" :filterable="filterable" :placeholder="placeholder">
      <el-option v-for="item in optionsData" :key="item[props.value]" :label="item[props.label]" :value="item[props.value]" />
    </el-select>
    <span v-else class="disabledText">{{ selectLabel }}</span>
  </div>
</template>

<script>
import { isObject, isArray } from "@/utils/validate"

export default {
  name: 'XSelect',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String, Object, Array],
      default () {
        return ""
      }
    },
    options: {
      type: Array,
      default () {
        return []
      }
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: true
    },
    props: {
      type: Object,
      default () {
        return {
          label: "label",
          value: "value"
        }
      }
    },
    width: {
      type: String,
      default: "260px"
    },
    clearable: {
      type: Boolean,
      default: false
    },
    transform: {
      type: String
    },
    require: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    optionsData: {
      get () {
        return this.options
      }
    },
    val: {
      get () {
        this.initValue()
        this.requireFun(this.value)
        return this.value
      },
      set (value) {
        this.$emit('update:value', value)
      }
    },
    selectLabel () {
      let val = ""
      if (isObject(this.value)) {
        // 如果是对象，例如：将{ value: 1, label: "测试1" }  转换为  "测试1"
        val = this.value[this.props.label]
      } else if (isArray(this.value) && this.value.length > 0) {
        if (isObject(this.value[0])) {
          // 如果是键值对，例如：将[{ value: 1, label: "测试1" }, { value: 2, label: "测试2" }] 转换为 "测试1,测试2"
          val = this.objectToString(this.value)
        } else {
          const arrVal = this.optionsData.filter(item => this.value.includes(item[this.props.value]))
          val = this.objectToString(arrVal)
        }
      } else {
        // 如果是单纯的一个数字，例如：1 直接查找数字匹配的键值对的label
        const valObj = this.optionsData.find(item => item[this.props.value] === this.value)
        if (valObj) {
          val = valObj[this.props.label]
        }
      }
      return val || '-'
    }
  },
  watch: {
    value (val) {
      if (val) {
        this.initValue(val)
      }
    }
  },
  methods: {
    initValue () {
      let result = this.value
      if (this.multiple && isArray(this.value) && this.value.length > 0) {
        if (isObject(this.value[0])) {
          // 将 [{ value: 1, label: "测试1" }] 转变为 [1]
          result = this.objectToNumber(this.value)
        }
      } else if (isObject(this.value)) {
        // 将{ value: 1, label: "测试1" } 转变为 1
        result = this.value[this.props.value]
      }
      if (result && this.transform && this.transform === "number") {
        result = Number(result)
      }
      this.$emit('update:value', result !== "" ? result : "")
    },
    change (val) {
      this.requireFun(val)
      this.$emit('change', val)
    },
    requireFun (val) {
      if (this.require && this.$refs.selectObj) {
        this.$nextTick(() => {
          const _target = this.$refs.selectObj.$el
          const item = _target.querySelector('.el-input__inner')
          if (!val) {
            item.style.borderColor = '#ff4949'
            item.classList.add('is-error')
          } else {
            item.style.borderColor = '#DCDFE6'
            item.classList.remove('is-error')
          }
        })
      }
    },
    objectToString (value) {
      return value.reduce((arr, item) => {
        arr.push(item[this.props.label])
        return arr
      }, []).join("、")
    },
    objectToNumber (value) {
      const arr = []
      value.forEach(item => {
        arr.push(item[this.props.value])
      })
      return arr
    }
  }
}
</script>

<style scoped lang="scss">
.xy-select {
  ::v-deep .is-focus {
    ::v-deep .el-input__suffix {
      top: -4px !important;
    }
  }
}
</style>

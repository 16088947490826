import vue from 'vue'
import taskNotify from './taskNotify'

// 创建vue组件实例
const Notify = vue.extend(taskNotify);

// 添加通知节点(用来存放通知的元素)
export const notifyWrap = document.createElement('div');
notifyWrap.className = "notify-wrap"
notifyWrap.style = "position: fixed; right: 0px; top: 90px; transition-duration: .5s;"
document.body.appendChild(notifyWrap);

const myMsg = {
  /*
   * 通知框
   * @notifyFlag 是否显示弹框
   * @taskNum 区域任务数
   * @lineNum 航线任务数
   * @time 显示时长 60*1000=1分钟
   */
  notify: ({
    notifyFlag,
    taskNum,
    lineNum,
    time
  }) => {
    // 创建一个存放通知的div
    const notifyDom = new Notify({
      el: document.createElement('div'),
      data () {
        return {
          notifyFlag: notifyFlag, // 是否显示
          time: time, // 取消按钮是否显示
          taskNum: taskNum, // 区域任务数
          lineNum: lineNum, // 航线任务数
          timer: null,
          timeFlag: false
        }
      },
      watch: {
        timeFlag () {
          if (this.timeFlag) {
            this.notifyFlag = false
            window.clearTimeout(this.timer);
          }
        }
      },
      created () {
        this.timer = setTimeout(() => {
          this.timeFlag = true;
        }, this.time);
      },
      beforeDestroy () {
        window.clearTimeout(this.timer);
      }
    })
    // 往notifyWrap里面添加通知
    notifyWrap.appendChild(notifyDom.$el)
  }
}

// 注册
function register () {
  vue.prototype.$myMsg = myMsg
}

export default {
  myMsg,
  register
}

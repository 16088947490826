import asyncRouter from "@/router/asyncRouter"

const state = {
  activeModuleName: "",
  havePermissionRouter: [],
  lizhi: false
}

const mutations = {
  Set_module_name: (state, title) => {
    state.activeModuleName = title
  },
  Set_permission_router: (state, title) => {
    state.havePermissionRouter = title
  },
  Set_lizhi: (state, val) => {
    state.lizhi = val
  }
}

const actions = {
  setMduleName ({ commit, state }, moduleName) {
    commit('Set_module_name', moduleName)
  },
  generateRoutes ({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < asyncRouter.length; i++) {
        const pitem = asyncRouter[i]
        for (let j = 0; j < pitem.children.length; j++) {
          const sitem = pitem.children[j]
          if (sitem.meta.roles.indexOf(data.type) === -1) {
            pitem.children.splice(j, 1)
            j--
          }
        }
        if (pitem.children.length === 0) {
          asyncRouter.splice(i, 1)
          i--
        }
        if (data.type === 6 && data.belong) {
          // 飞手已有所属公司，就把入职申请路由去掉
          commit("Set_lizhi", true)
          // asyncRouter.splice(i, 1)
          // i--
        }
      }
      commit("Set_permission_router", asyncRouter)
      resolve(asyncRouter)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

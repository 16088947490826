import Vue from 'vue'
import App from './App.vue'

import Cookies from "js-cookie";

import Element from "element-ui";
import "./styles/element-variables.scss";

import router from './router'
import store from "./store";

import "@/styles/index.scss"; // global css
import "@/styles/reset.scss";

import "./permission"; // permission control
import "./icons"; // icon

import '@/utils/element.js'

import * as dayjs from 'dayjs'
Vue.prototype.$dayjs = dayjs

// 引入 ECharts 主模块
// var echarts = require('echarts/lib/echarts');
// 引入柱状图
// require('echarts/lib/chart/bar');
// // 引入提示框和标题组件
// require('echarts/lib/component/tooltip');
// require('echarts/lib/component/title');
// console.log(echarts);

// 首页websocket
// import websocket from '@/utils/websocket.js'
// Vue.prototype.$ws = websocket

// 首页任务弹窗提醒
import message from "@/views/home/components/notify.js"
Vue.use(message.register)

import myDirective from '@/directive'
Vue.use(myDirective)

import XiangMi from "@/components/XiangMi"
Vue.use(XiangMi)

Vue.config.productionTip = false

Vue.use(Element, {
  size: Cookies.get("size") || "medium" // set element-ui default size
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

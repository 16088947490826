<template>

  <div class="navbar">
    <div class="left-logo">
      <span class="logo-text">北京唯科致远科技有限公司</span>
    </div>
    <div class="right-menu">
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <img :src="$store.getters.avatar" class="user-avatar">
          <span class="userName">{{ $store.getters.userName || "-" }}</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item divided @click.native="$emit('resetPass',true)">
            <span style="display:block;">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item v-if="$store.getters.userType === 6 && !$store.getters.lizhi" divided @click.native="$emit('inductionApply',true)">
            <span style="display:block;">申请入职</span>
          </el-dropdown-item>
          <el-dropdown-item v-if="$store.getters.userType === 6 && $store.getters.lizhi" divided @click.native="lizhi()">
            <span style="display:block;">离职</span>
          </el-dropdown-item>
          <!-- leaveOffice -->
          <el-dropdown-item v-if="$store.getters.userType === 0 || $store.getters.typereport === 1" @click.native="report()">
            <span style="display:block;">查看报表</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { MessageBox } from 'element-ui'
import { driverQuite } from '@/api/driver.js' // 离职
import { logout } from '@/api/user.js'
import { notifyWrap } from "@/views/home/components/notify.js"

export default {
  computed: {
    ...mapGetters(["sidebar", "avatar", "device"])
  },
  methods: {
    toggleSideBar () {
      this.$store.dispatch("app/toggleSideBar");
    },
    // 退出
    async logout () {
      if(([100, 99, 98,97].includes(this.$store.getters.userType))){
        notifyWrap.remove()
        await logout();
        await this.$store.dispatch("user/logout");
        this.$router.push(`/login/bom`);
        return;
      }
      // 消除首页任务弹窗notify
      notifyWrap.remove()
      await logout();
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login`);
    },
    report(){
      this.$router.push({path:'/reports'})
    },
    modifyPassword () {

    },
    lizhi () {
      MessageBox.confirm(`确定离职`, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          driverQuite({
          }).then(_ => {
            this.$msg.success('操作成功')
          })
        }).catch(err => {
          console.log(err);
        })
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 40px;
  overflow: hidden;
  position: relative;
  background: rgba($color: #fff, $alpha: 0.2);
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: flex;
  justify-content: space-between;
  .hamburger-container {
    line-height: 40px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .left-logo {
    line-height: 40px;
    .logo-text {
      color: #fff;
      font-size: 14px;
      margin-left: 15px;
    }
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 40px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        height: 40px;
        font-size: 12px;
        .user-avatar {
          cursor: pointer;
          width: 28px;
          height: 28px;
          border-radius: 100%;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
        .userName {
          color: #fff;
          margin: 0 10px;
        }
        .userAccount {
          color: #999;
        }
      }
    }
  }
  position: fixed;
  top: 0;
  width: 100%;
  background: linear-gradient(to bottom, 
                            #2cc1f3ff 0%, 
                            #85c4f1ff 50%,
                            #89d4f4ff 100%);
  z-index: 1000;
}
</style>

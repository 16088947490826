import request from '@/utils/request'

export function login (data) {
  return request({
    url: '/User/Login',
    method: 'post',
    data
  })
}
export function loginbom (data) {
  return request({
    url: '/bom/login',
    method: 'post',
    data
  })
}

// 用户注册
export function regist (data) {
  return request({
    url: '/User/Regist',
    method: 'post',
    data
  })
}

// 用户退出
export function logout (data) {
  return request({
    url: '/User/Logout',
    method: 'post',
    data
  })
}

// 注册前查重 注册用户时，当用户名、手机号、电子邮箱输入框失去焦点时，发送此请求
export function checkDuplicateApi (data) {
  return request({
    url: '/User/CheckDuplicate',
    method: 'post',
    data
  })
}
// 获取通航公司列表
export function companyListApi () {
  return request({
    url: '/User/CompanyList',
    method: 'get'
  })
}
// 索引信息
export function getUserInfo (data) {
  return request({
    url: '/Index/Info',
    method: 'post',
    data
  })
}
// 索引设备信息
export function getHomeInfo (data) {
  return request({
    url: '/Index/Device',
    method: 'post',
    data
  })
}
// 索引人员在线、离线统计
export function getPersonHomeInfo (data) {
  return request({
    url: '/Admin/User/OnLine',
    method: 'post',
    data
  })
}
// 开始实时更新设备分布
export function realtimeStart (data) {
  return request({
    url: '/Index/Device/Realtime/Start',
    method: 'post',
    data
  })
}

export function realtimeStop (data) {
  return request({
    url: 'Device/Realtime/Stop',
    method: 'post',
    data
  })
}

// 账户列表
export function getUsersList (data) {
  return request({
    url: '/Admin/User/List',
    method: 'post',
    data
  })
}

// 账户数量
export function getUsersCount (data) {
  return request({
    url: '/Admin/User/Count',
    method: 'post',
    data
  })
}

// 账户审核
export function usersAudit (data) {
  return request({
    url: '/Admin/User/Audit',
    method: 'post',
    data
  })
}
// 根据账号ID获取账号名称
export function getUserName (data) {
  return request({
    url: '/User/Name',
    method: 'post',
    data
  })
}

// 账号数据导出
export function exportUserInfo (query) {
  return request({
    url: '/Admin/User/Export',
    method: 'post',
    params: query,
    responseType: 'blob'
  })
}

// 用户详情
export function getUserDetail (data) {
  return request({
    url: '/User/Detail',
    method: 'post',
    data
  })
}

// 编辑用户
export function updateUser (data) {
  return request({
    url: '/User/Edit',
    method: 'post',
    data
  })
}

// 信息统计
export function getUserInfoStatis (data) {
  return request({
    url: '/Admin/User/InfoStatis',
    method: 'post',
    data
  })
}
// 公司列表
export function getCompanyList (data){
  return request({
    url: '/Admin/Company/List',
    method: 'post',
    data
  })
}
// 修改密码
export function modifyPassword(data){
  return request({
    url:'/User/Password',
    method: 'post',
    data
  })
}
//添加厂商用户
export function addFuser(data){
  return request({
    url:'/fuser/add',
    method:'post',
    data
  })
}
//厂商列表
export function getFuserList(data){
  return request({
    url:'/fuser/list',
    method:'post',
    data
  })
}
<template>
  <button class="x-button" :disabled="disabled || loading" :class="[
    size ? 'x-button--' + size : '',
    type ? 'x-button--' + type : '',
    {
      'is-disabled' : disabled,
      'is-loading' : loading
    }]">
    <i class="x-icon-loading" v-if="loading"></i>
    <svg-icon icon-class="icon" v-if="icon"></svg-icon>
    <span v-if="$slots.default">
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  name: "XButton",
  props: {
    type: {
      type: String,
      default: 'default'
    },
    size: String,
    icon: {
      type: String,
      default: ''
    },
    loading: Boolean,
    disabled: Boolean
  }
}
</script>

<style lang="scss">
.x-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: rgba(30, 208, 208, 0.2);
  border: 1px solid #dcdfe6;
  border-color: transparent;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  font-weight: 400;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  font-size: 14px;
  border-radius: 4px;

  position: relative;
  padding: 10px 30px;
  color: #21ebff;
  text-transform: uppercase;
  transition: 0.5s;
  overflow: hidden;
}

.x-button--default {
  filter: hue-rotate(360deg);
}
.x-button--primary {
  filter: hue-rotate(115deg);
}
.x-button--warning {
  filter: hue-rotate(270deg);
}
.x-button:hover {
  background: #21ebff;
  color: #202020;
  box-shadow: 0 0 50px #21ebff;
  transition-delay: 0.1s;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0003);
}
.x-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-top: 2px solid #21ebff;
  border-left: 2px solid #21ebff;
  transition: 0.5s;
  transition-delay: 0.2s;
}
.x-button:hover::before {
  width: 100%;
  height: 100%;
  transition-delay: 0s;
}
.x-button::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #21ebff;
  border-right: 2px solid #21ebff;
  transition: 0.5s;
  transition-delay: 0.2s;
}
.x-button:hover::after {
  width: 100%;
  height: 100%;
  transition-delay: 0s;
}
</style>

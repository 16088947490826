import { accountListApi } from "@/api/equipment"
import { LS } from "@/utils/auth"

const state = {
  staticData: {
    airportOption: [],
    batteryOption: [],
    dianTiaoOption: [],
    faDianOption: [],
    netOption: [],
    canOption: [],
    companyOption: [],
    servicerOption: []
  }
}

const mutations = {
  Set_static_data: (state, val) => {
    state.staticData = val
    LS.set("staticData", val)
  }
}
const actions = {
  async getStaticData ({ commit, state }, type) {
    let staticData = LS.get("staticData")
    if (!staticData) {
      staticData = {
        airportOption: [],
        batteryOption: [],
        dianTiaoOption: [],
        faDianOption: [],
        netOption: [],
        canOption: [],
        companyOption: [],
        servicerOption: []
      }
      // 控制飞行机场：
      if (!type || type === 2) {
        var { info } = await accountListApi({ type: 2 })
        staticData.airportOption = info
      }
      // 设备服务商：
      if (!type || type === 3) {
        var { info } = await accountListApi({ type: 3 })
        staticData.servicerOption = info
      }
      // 通航公司：
      if (!type || type === 5) {
        var { info } = await accountListApi({ type: 5 })
        staticData.companyOption = info
      }
      // 电池ID：
      if (!type || type === 9) {
        var { info } = await accountListApi({ type: 9 })
        staticData.batteryOption = info
      }
      // 电调ID：
      if (!type || type === 10) {
        var { info } = await accountListApi({ type: 10 })
        staticData.dianTiaoOption = info
      }
      // 发动机/电机ID：
      if (!type || type === 11) {
        var { info } = await accountListApi({ type: 11 })
        staticData.faDianOption = info
      }
      // 网络模块：
      if (!type || type === 12) {
        var { info } = await accountListApi({ type: 12 })
        staticData.netOption = info
      }
      // CAN设备模块：
      if (!type || type === 15) {
        var { info } = await accountListApi({ type: 15 })
        staticData.canOption = info
      }
      commit('Set_static_data', staticData)
      return Promise.resolve(staticData)
    } else {
      return Promise.resolve(staticData)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

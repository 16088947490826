<template>
  <div>
    <Navbar @inductionApply="inductionApply" @resetPass="resetPass"/>
    <InductionApply :show.sync="showInductionApply"/>
    <resetPass :show.sync="showresetPass" />
    <TopMenu />
    <!-- <keep-alive> -->
      <router-view></router-view>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import TopMenu from "./components/TopMenu"
import InductionApply from "./components/InductionApply"
import resetPass from "./components/resetPass"

export default {
  components: { Navbar, TopMenu, InductionApply, resetPass },
  data () {
    return {
      showInductionApply: false,
      showresetPass:false
    }
  },
  methods: {
    inductionApply (e) {
      this.showInductionApply = e
    },
    resetPass(e){
      this.showresetPass = e
    }
  }
}
</script>

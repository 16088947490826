import { login, getUserInfo, regist,loginbom } from '@/api/user'
import { getToken, setToken, removeToken, LS } from '@/utils/auth'
import store from '@/store'
import router, { resetRouter } from '@/router'
import md5 from 'md5'

const state = {
  token: getToken(),
  userName: '',
  avatar: '',
  introduction: '',
  roles: ['admin'],
  permissions: [],
  userId: '',
  tenantId: 1000,
  isAdmin: false,
  userTenantId: '',
  employeeAccount: '',
  userType: '',
  belong: "",
  typereport:''
}
// 租户id默认传1000   租户id返回0代表超管
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.userName = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_BLONG: (state, belong) => {
    state.belong = belong
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_ID: (state, id) => {
    state.userId = id
  },
  SET_TYPE: (state, val) => {
    state.userType = val
    if (val === 0) {
      state.isAdmin = true
    }
  },
  SET_TYPEREPORT:(state,typereport) =>{
    state.typereport = typereport
  }
}

const actions = {
  // user login
  login ({ commit, dispatch }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({
        username: username,
        password: md5(password)
          .substring(8, 24)
          .toLocaleUpperCase()
      })
        .then(async (response) => {
          const { info } = response
          commit('SET_TOKEN', info)
          setToken(info)

          await dispatch('roleManage')
          // await dispatch('getUserInfo')
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  loginbom ({ commit, dispatch }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      loginbom({
        username: username,
        password: md5(password)
          .substring(8, 24)
          .toLocaleUpperCase()
      })
        .then(async (response) => {
          const { info } = response
          commit('SET_TOKEN', info)
          setToken(info)

          await dispatch('roleManage')
          // await dispatch('getUserInfo')
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // user regist
  async roleManage ({ commit, dispatch }) {
    const data = await dispatch('getUserInfo')
    const accessRoutes = await store.dispatch('permission/generateRoutes', data)
    router.addRoutes(accessRoutes)
  },
  // get user info
  getUserInfo ({ commit, state }) {
    return new Promise((resolve, reject) => {
      getUserInfo()
        .then((res) => {
          if (!res.info) {
            reject('授权失败，请重新登陆！')
          }
          const { name, id, type, avatar, belong, typereport } = res.info
          commit('SET_NAME', name)
          commit('SET_ID', id)
          commit('SET_TYPE', type)
          commit('SET_AVATAR', avatar)
          commit('SET_BLONG', belong)
          commit('SET_TYPEREPORT', typereport)
          resolve(res.info)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // user regist
  regist ({ commit, dispatch }, registForm) {
    return new Promise((resolve, reject) => {
      regist(registForm)
        .then(async (response) => {
          const { info } = response
          resolve(info)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user logout
  logout ({ commit, state, dispatch }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resetRouter()
      LS.clear()
      dispatch('tagsView/delAllViews', null, { root: true })
      resolve()
    })
  },
  // remove token
  resetToken ({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },
  // 动态地修改权限
  async changeRoles ({ commit, dispatch }, moduleTitle) {
    // 切换侧边栏菜单展示
    await dispatch('permission/changeSideBar', moduleTitle, {
      root: true
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>
<style>
  body {
    padding-top: 40px;
  }
  </style>
const getters = {
  token: state => state.user.token,
  userType: state => state.user.userType,
  userName: state => state.user.userName,
  userId: state => state.user.userId,
  isAdmin: state => state.user.isAdmin,
  avatar: state => state.user.avatar,
  belong: state => state.user.belong,
  activeModuleName: state => state.permission.activeModuleName,
  havePermissionRouter: state => state.permission.havePermissionRouter,
  lizhi: state => state.permission.lizhi,
  typereport: state => state.user.typereport
}
export default getters
